<template>
  <v-container fluid>
    <v-row>
      <v-col cols="9">
        <base-material-card
          color="primary"
          icon="mdi-map-search"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Visualizar entidades geográficas no mapa
            </div>
          </template>
          <v-row
            align="center"
            no-gutters
            class="ml-0 mt-3"
          >
            <v-col cols="12">
              <div id="visualizar-mapa-entidades-geo-bdgd"></div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      <v-col cols="3">
        <base-material-card
          color="primary"
          icon="mdi-calendar-edit"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Alterar versão da BDGD
            </div>
          </template>
          <div id="selecionar-versao-bdgd">
            <v-row
              align="center"
              no-gutters
              class="ml-0 mt-2"
            >
              <v-col cols="12">
                <select-all-bdgd-versions
                  label="Versão da BDGD"
                  :companyId="companyId"
                  @bdgdVersionSelected="bdgdVersionSelected"
                />
              </v-col>
            </v-row>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import L from 'leaflet';
import wms from 'leaflet.wms';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import BasesDadosService from '@/services/BasesDadosService';
import GeoServerService from '@/services/GeoServerService';
import tourMixins from '@/mixins/tourMixins';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import scaleMixins from '@/mixins/leaflet/scaleMixins';
import drawMixins from '@/mixins/leaflet/drawMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';
import dateFormatterMixins from '@/mixins/dateFormatterMixins';
import dayjs from 'dayjs';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  mixins: [
    tourMixins,
    geocoderMixins,
    browserPrintMixins,
    scaleMixins,
    drawMixins,
    filtersMixins,
    dateFormatterMixins
  ],

  components: {
    SelectAllBdgdVersions: () =>
      import('@/components/general/SelectAllBdgdVersions.vue')
  },

  data() {
    return {
      mes: null,
      versaoBdgd: null,
      bases: [],
      map: null,
      mapaId: 'visualizar-mapa-entidades-geo-bdgd',
      mySource: null,
      layerControl: null,
      layers: {},
      arrTour: [
        {
          element: '#selecionar-versao-bdgd',
          intro:
            'Selecione o mês e a versão da BDGD que você deseja visualizar.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#visualizar-mapa-entidades-geo-bdgd',
          intro:
            'As entidades serão plotadas neste mapa. Você pode selecionar as entidades que deseja visualizar clicando no ícone de seleção de camadas, presente no lado superior direito do mapa.',
          scrollTo: 'tooltip',
          position: 'bottom'
        }
      ]
    };
  },

  mounted() {
    this.configuraMapa(4);

    // Coloca o centro do mapa próximo a capital onde está a distribuidora
    let [longitude, latitude] =
      this.$store.getters.getCompanyCapitalCoordinates;

    this.setView(latitude, longitude);

    this.map.setZoom(18);

    this.iniciarTourPagina();
  },

  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    },

    mesDados() {
      return this.mes ? `${this.mes}-01` : null;
    },

    cqlFilter() {
      return this.companyId && this.mesDados && this.versaoBdgd
        ? this.getBasicCqlFilter(this.companyId, this.mesDados, this.versaoBdgd)
        : this.getBasicCqlFilter(this.companyId);
    },

    ogcFilter() {
      return this.companyId && this.mesDados && this.versaoBdgd
        ? this.getBasicOgcFilter(this.companyId, this.mesDados, this.versaoBdgd)
        : this.getBasicOgcFilter(this.companyId);
    }
  },

  watch: {
    cqlFilter() {
      this.addBasesLayers();
    },

    versaoBdgd(newVersao) {
      if (newVersao && this.mesDados)
        this.getBasesDados(this.mesDados, newVersao);
    },

    mesDados(newMesDados) {
      if (newMesDados && this.versaoBdgd)
        this.getBasesDados(newMesDados, this.versaoBdgd);
    }
  },

  methods: {
    bdgdVersionSelected(bdgdVersion) {
      this.mes = this.formatMonthToPicker(bdgdVersion.mes);
      this.versaoBdgd = bdgdVersion.versao;
    },

    getBasesDados(dataRegistro, versao) {
      BasesDadosService.getBasesDadosGeograficasLiberadas(
        this.companyId,
        dataRegistro,
        versao
      )
        .then((response) => {
          this.bases = response.data;
          this.limparBasesLayers();
          this.addBasesLayers();

          if (!this.bases.length) {
            this.$toast.warning(
              'Não existe nenhuma entidade geografica disponível para esse mês e versão da BDGD escolhida.',
              '',
              { position: 'topRight' }
            );
          }
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao recuperar bases de dados para layers.',
            '',
            { position: 'topRight' }
          );
        });
    },

    getSelectedLayersNames() {
      return Object.keys(this.layers).filter((layerName) =>
        this.map.hasLayer(this.layers[layerName])
      );
    },

    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });

      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);

      this.createScaleControl(L).addTo(this.map);
      this.createGeocoderControl(L, this.map).addTo(this.map);
      this.createBrowserPrintControl(L, 'Visualizar Mapa').addTo(this.map);
      this.configDrawControl(L);
    },

    configDrawControl(L) {
      let { drawnItems, drawControl } = this.createDrawControl(L);

      this.map.addLayer(drawnItems);
      this.map.addControl(drawControl);

      this.map.on('draw:created', ({ layer: polygon }) => {
        drawnItems.addLayer(polygon);

        // Efetua o download os arquivos com as feições dentro do poligono criado
        this.getSelectedLayersNames().forEach((selectedLayer) => {
          const arrLatLngs = polygon.getLatLngs();

          this.downloadCsvFeaturesWithinPolygon(selectedLayer, arrLatLngs[0]);
        });
      });

      this.map.on('draw:edited', ({ layers: polygons }) => {
        polygons.eachLayer((polygon) => {
          // Efetua o download os arquivos com as feições dentro do poligono criado
          this.getSelectedLayersNames().forEach((selectedLayer) => {
            const arrLatLngs = polygon.getLatLngs();

            this.downloadCsvFeaturesWithinPolygon(selectedLayer, arrLatLngs[0]);
          });
        });
      });
    },

    downloadCsvFeaturesWithinPolygon(layerName, polygonLatLngs) {
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      const promise = this.isCqlFilter()
        ? GeoServerService.downloadCsvFeaturesWithinPolygon(
            layerName,
            polygonLatLngs,
            this.cqlFilter,
            0 // maxFeatures
          )
        : GeoServerService.downloadCsvFeaturesWithinPolygonUsingOgcFilter(
            layerName,
            polygonLatLngs,
            this.ogcFilter,
            0 // maxFeatures
          );

      promise
        .then((res) => {
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const nomeArquivo = `${layerName}_feicoes_selecionadas_${timestamp}.csv`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo CSV com as feições selecionadas.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        });
    },

    setView(latitude = -2.5606242, longitude = -44.4016494) {
      this.map.setView([latitude, longitude]);
    },

    addBasesLayers() {
      this.bases.forEach((base) => this.addBaseLayer(base));
    },

    limparBasesLayers() {
      Object.keys(this.layers).forEach((nomeTabela) => {
        this.layerControl.removeLayer(this.layers[nomeTabela]);
      });
    },

    addBaseLayer(base) {
      const geoserverBaseUrl = GeoServerService.getGeoServerBaseUrl();
      const baseUrl = GeoServerService.getWmsBaseUrl();
      const workspace = GeoServerService.getWorkspaceDefault();

      // Se o layer já existe, remove-o
      if (this.layers[base.nome_tabela]) {
        this.layerControl.removeLayer(this.layers[base.nome_tabela]);
      }

      const ignoredProperties = [
        'GEOM',
        'CONTADOR',
        'COMPANY',
        'COMPANY_ID',
        'MES_DADOS',
        'BDGD_VERSAO',
        'BDGD_VERSAO_ID'
      ];

      const MySource = wms.Source.extend({
        showFeatureInfo: (latlng, info) => {
          if (info.includes('no features were found')) {
            return;
          }

          let arrInfo = info.split('\n');
          let stringInfo = '';

          arrInfo.forEach((element, key) => {
            if ([0, 1, arrInfo.length - 1, arrInfo.length - 2].includes(key))
              return;

            if (element.slice(-4) == 'null') return;

            element = element.split('=');
            let property = element[0].trim().toUpperCase();
            if (ignoredProperties.includes(property)) return;

            element = property + ': ' + element[1].trim();
            stringInfo += `${element}<br>`;
          });

          L.popup()
            .setLatLng(latlng)
            .setContent(`<div id="div-popup">${stringInfo}</div>`)
            .openOn(this.map);
        }
      });

      let config = {
        format: 'image/png',
        transparent: true,
        identify: true // Funcionalidade de clicar na feição e obter os dados
      };

      // Verifica qual tipo de filtro será utilizado, CQL ou OGC e configura o parâmetro correto
      this.isCqlFilter()
        ? (config.cql_filter = this.cqlFilter)
        : (config.filter = this.ogcFilter);

      // Cria o layer
      let source = new MySource(`${geoserverBaseUrl}/${baseUrl}`, config);
      this.layers[base.nome_tabela] = source.getLayer(
        `${workspace}:${base.nome_tabela}`
      );

      // Adiciona o layer no mapa
      this.layerControl.addOverlay(
        this.layers[base.nome_tabela],
        base.descricao
      );
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;
      this.iniciarTour(this.arrTour);
    }
  }
};
</script>

<style>
#visualizar-mapa-entidades-geo-bdgd {
  height: 75vh;
  z-index: 0;
}

#div-popup {
  max-height: 200px;
  overflow-y: auto;
}
</style>
